export const countryIcons = {
  andorra: require('./country-icons/andorra.png'),
  unitedArabEmirates: require('./country-icons/unitedarabemirates.png'),
  afghanistan: require('./country-icons/afghanistan.png'),
  anguilla: require('./country-icons/anguilla.png'),
  albania: require('./country-icons/albania.png'),
  armenia: require('./country-icons/armenia.png'),
  angola: require('./country-icons/angola.png'),
  argentina: require('./country-icons/argentina.png'),
  americanSamoa: require('./country-icons/americansamoa.png'),
  austria: require('./country-icons/austria.png'),
  australia: require('./country-icons/australia.png'),
  aruba: require('./country-icons/aruba.png'),
  azerbaijan: require('./country-icons/azerbaijan.png'),
  barbados: require('./country-icons/barbados.png'),
  bangladesh: require('./country-icons/bangladesh.png'),
  belgium: require('./country-icons/belgium.png'),
  burkinaFaso: require('./country-icons/burkinafaso.png'),
  bulgaria: require('./country-icons/bulgaria.png'),
  bahrain: require('./country-icons/bahrain.png'),
  burundi: require('./country-icons/burundi.png'),
  benin: require('./country-icons/benin.png'),
  bermuda: require('./country-icons/bermuda.png'),
  brunei: require('./country-icons/brunei.png'),
  bolivia: require('./country-icons/bolivia.png'),
  brazil: require('./country-icons/brazil.png'),
  bahamas: require('./country-icons/bahamas.png'),
  bhutan: require('./country-icons/bhutan.png'),
  botswana: require('./country-icons/botswana.png'),
  belarus: require('./country-icons/belarus.png'),
  belize: require('./country-icons/belize.png'),
  canada: require('./country-icons/canada.png'),
  centralAfricanRepublic: require('./country-icons/centralafricanrepublic.png'),
  switzerland: require('./country-icons/switzerland.png'),
  cookIslands: require('./country-icons/cookislands.png'),
  chile: require('./country-icons/chile.png'),
  cameroon: require('./country-icons/cameroon.png'),
  china: require('./country-icons/china.png'),
  colombia: require('./country-icons/colombia.png'),
  costaRica: require('./country-icons/costarica.png'),
  cuba: require('./country-icons/cuba.png'),
  capeVerde: require('./country-icons/capeverde.png'),
  cyprus: require('./country-icons/cyprus.png'),
  germany: require('./country-icons/germany.png'),
  djibouti: require('./country-icons/djibouti.png'),
  denmark: require('./country-icons/denmark.png'),
  dominica: require('./country-icons/dominica.png'),
  dominicanRepublic: require('./country-icons/dominicanrepublic.png'),
  algeria: require('./country-icons/Algeria.png'),
  ecuador: require('./country-icons/ecuador.png'),
  estonia: require('./country-icons/estonia.png'),
  egypt: require('./country-icons/egypt.png'),
  eritrea: require('./country-icons/eritrea.png'),
  spain: require('./country-icons/spain.png'),
  ethiopia: require('./country-icons/ethiopia.png'),
  europeanUnion: require('./country-icons/europeanunion.png'),
  finland: require('./country-icons/finland.png'),
  fiji: require('./country-icons/fiji.png'),
  falklandIslands: require('./country-icons/falklandislands.png'),
  micronesia: require('./country-icons/micronesia.png'),
  faroeIslands: require('./country-icons/faroeislands.png'),
  france: require('./country-icons/france.png'),
  gabon: require('./country-icons/gabon.png'),
  unitedKingdom: require('./country-icons/unitedkingdom.png'),
  grenada: require('./country-icons/grenada.png'),
  georgia: require('./country-icons/georgia.png'),
  frenchGuiana: require('./country-icons/frenchguiana.png'),
  guernsey: require('./country-icons/guernsey.png'),
  ghana: require('./country-icons/ghana.png'),
  gibraltar: require('./country-icons/gibraltar.png'),
  greenland: require('./country-icons/greenland.png'),
  gambia: require('./country-icons/gambia.png'),
  guinea: require('./country-icons/guinea.png'),
  equatorialGuinea: require('./country-icons/equatorialguinea.png'),
  greece: require('./country-icons/greece.png'),
  guatemala: require('./country-icons/guatemala.png'),
  guam: require('./country-icons/guam.png'),
  guyana: require('./country-icons/guyana.png'),
  honduras: require('./country-icons/honduras.png'),
  croatia: require('./country-icons/croatia.png'),
  haiti: require('./country-icons/haiti.png'),
  hungary: require('./country-icons/hungary.png'),
  canaryIslands: require('./country-icons/canaryislands.png'),
  indonesia: require('./country-icons/indonesia.png'),
  ireland: require('./country-icons/ireland.png'),
  israel: require('./country-icons/israel.png'),
  isleOfMan: require('./country-icons/isleofman.png'),
  india: require('./country-icons/india.png'),
  britishIndianOceanTerritory: require('./country-icons/britishindianoceanterritory.png'),
  iraq: require('./country-icons/iraq.png'),
  iran: require('./country-icons/iran.png'),
  iceland: require('./country-icons/iceland.png'),
  italy: require('./country-icons/italy.png'),
  jersey: require('./country-icons/jersey.png'),
  jamaica: require('./country-icons/jamaica.png'),
  jordan: require('./country-icons/jordan.png'),
  japan: require('./country-icons/japan.png'),
  kenya: require('./country-icons/kenya.png'),
  kyrgyzstan: require('./country-icons/kyrgyzstan.png'),
  cambodia: require('./country-icons/cambodia.png'),
  kiribati: require('./country-icons/kiribati.png'),
  comoros: require('./country-icons/comoros.png'),
  northKorea: require('./country-icons/northkorea.png'),
  southKorea: require('./country-icons/southkorea.png'),
  kuwait: require('./country-icons/kuwait.png'),
  caymanIslands: require('./country-icons/caymanislands.png'),
  kazakhstan: require('./country-icons/kazakhstan.png'),
  laos: require('./country-icons/laos.png'),
  lebanon: require('./country-icons/lebanon.png'),
  liechtenstein: require('./country-icons/liechtenstein.png'),
  sriLanka: require('./country-icons/srilanka.png'),
  liberia: require('./country-icons/liberia.png'),
  lesotho: require('./country-icons/lesotho.png'),
  lithuania: require('./country-icons/lithuania.png'),
  luxembourg: require('./country-icons/luxembourg.png'),
  latvia: require('./country-icons/latvia.png'),
  libya: require('./country-icons/libya.png'),
  morocco: require('./country-icons/morocco.png'),
  monaco: require('./country-icons/monaco.png'),
  moldova: require('./country-icons/moldova.png'),
  montenegro: require('./country-icons/montenegro.png'),
  madagascar: require('./country-icons/madagascar.png'),
  mali: require('./country-icons/mali.png'),
  mongolia: require('./country-icons/mongolia.png'),
  martinique: require('./country-icons/martinique.png'),
  mauritania: require('./country-icons/mauritania.png'),
  montserrat: require('./country-icons/montserrat.png'),
  malta: require('./country-icons/malta.png'),
  mauritius: require('./country-icons/mauritius.png'),
  maldives: require('./country-icons/maldives.png'),
  malawi: require('./country-icons/malawi.png'),
  mexico: require('./country-icons/mexico.png'),
  malaysia: require('./country-icons/malaysia.png'),
  mozambique: require('./country-icons/mozambique.png'),
  namibia: require('./country-icons/namibia.png'),
  niger: require('./country-icons/niger.png'),
  norfolkIsland: require('./country-icons/norfolkisland.png'),
  nigeria: require('./country-icons/nigeria.png'),
  nicaragua: require('./country-icons/nicaragua.png'),
  netherlands: require('./country-icons/netherlands.png'),
  norway: require('./country-icons/norway.png'),
  nepal: require('./country-icons/nepal.png'),
  nauru: require('./country-icons/nauru.png'),
  niue: require('./country-icons/niue.png'),
  newZealand: require('./country-icons/newzealand.png'),
  oman: require('./country-icons/oman.png'),
  panama: require('./country-icons/panama.png'),
  peru: require('./country-icons/peru.png'),
  frenchPolynesia: require('./country-icons/frenchpolynesia.png'),
  papuaNewGuinea: require('./country-icons/papuanewguinea.png'),
  philippines: require('./country-icons/philippines.png'),
  pakistan: require('./country-icons/pakistan.png'),
  poland: require('./country-icons/poland.png'),
  pitcairnIslands: require('./country-icons/pitcairnislands.png'),
  puertoRico: require('./country-icons/puertorico.png'),
  portugal: require('./country-icons/portugal.png'),
  palau: require('./country-icons/palau.png'),
  paraguay: require('./country-icons/paraguay.png'),
  qatar: require('./country-icons/qatar.png'),
  romania: require('./country-icons/romania.png'),
  serbia: require('./country-icons/serbia.png'),
  russia: require('./country-icons/russia.png'),
  rwanda: require('./country-icons/rwanda.png'),
  saudiArabia: require('./country-icons/saudiarabia.png'),
  solomonIslands: require('./country-icons/solomonislands.png'),
  seychelles: require('./country-icons/seychelles.png'),
  sudan: require('./country-icons/sudan.png'),
  sweden: require('./country-icons/sweden.png'),
  singapore: require('./country-icons/singapore.png'),
  slovenia: require('./country-icons/slovenia.png'),
  slovakia: require('./country-icons/slovakia.png'),
  sierraLeone: require('./country-icons/sierraleone.png'),
  sanMarino: require('./country-icons/sanmarino.png'),
  senegal: require('./country-icons/senegal.png'),
  somalia: require('./country-icons/somalia.png'),
  suriname: require('./country-icons/suriname.png'),
  southSudan: require('./country-icons/southsudan.png'),
  elSalvador: require('./country-icons/elsalvador.png'),
  sintMaarten: require('./country-icons/sintmaarten.png'),
  syria: require('./country-icons/syria.png'),
  chad: require('./country-icons/chad.png'),
  togo: require('./country-icons/togo.png'),
  thailand: require('./country-icons/thailand.png'),
  tajikistan: require('./country-icons/tajikistan.png'),
  tokelau: require('./country-icons/tokelau.png'),
  turkmenistan: require('./country-icons/turkmenistan.png'),
  tunisia: require('./country-icons/tunisia.png'),
  tonga: require('./country-icons/tonga.png'),
  turkey: require('./country-icons/turkey.png'),
  tuvalu: require('./country-icons/tuvalu.png'),
  taiwan: require('./country-icons/taiwan.png'),
  tanzania: require('./country-icons/tanzania.png'),
  ukraine: require('./country-icons/ukraine.png'),
  uganda: require('./country-icons/uganda.png'),
  unitedNations: require('./country-icons/unitednations.png'),
  unitedStates: require('./country-icons/unitedstates.png'),
  uruguay: require('./country-icons/uruguay.png'),
  uzbekistan: require('./country-icons/uzbekistan.png'),
  vaticanCity: require('./country-icons/vaticancity.png'),
  venezuela: require('./country-icons/venezuela.png'),
  britishVirginIslands: require('./country-icons/britishvirginislands.png'),
  vietnam: require('./country-icons/vietnam.png'),
  vanuatu: require('./country-icons/vanuatu.png'),
  samoa: require('./country-icons/samoa.png'),
  kosovo: require('./country-icons/kosovo.png'),
  yemen: require('./country-icons/yemen.png'),
  southAfrica: require('./country-icons/southafrica.png'),
  zambia: require('./country-icons/zambia.png'),
  zimbabwe: require('./country-icons/zimbabwe.png'),
  england: require('./country-icons/england.png'),
  scotland: require('./country-icons/scotland.png'),
  wales: require('./country-icons/wales.png'),
};

export const benefitsImage = {
  noCodeAlert: require('./benefits-images/noCodeAlert.png'),
  successCheck: require('./benefits-images/successCheck.png'),
  errorCheck: require('./benefits-images/errorCheck.png'),
  exclamationCheck: require('./benefits-images/exclamationCheck.png'),
};

export const mercadoPagoImage = require('./logo-mp.png');