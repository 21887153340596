import { useRef, useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import {
  useUserBikeStore,
  useTokenStore,
  usePrimaStore,
} from '../../../services/Insurance/InsuranceStore';
import { useInsuranceService } from '../../../services/Insurance/InsuranceService';
import type { InsurancePolicyCreate, PrimaData } from '../../../services/Insurance/InsuranceInterface';

export type ImageData = {
  uri?: string;
  file?: File;
} | null;

export const useHirePlanController = () => {
  const [suraBikletaPicture, setSuraBikletaPicture] = useState<ImageData>(null);
  const [wasImageManuallyDeleted, setWasImageManuallyDeleted] = useState(false);
  const [suraSerialNumber, setSuraSerialNumber] = useState<ImageData>(null);
  const [suraPadlockPicture, setSuraPadlockPicture] = useState<ImageData>(null);
  const [selectedQuote, setSelectedQuote] = useState<boolean | null>(null);
  const [isSameOwner, setIsSameOwner] = useState<boolean | null>(null);
  const [step, setStep] = useState<
    'paymentMethod' | 'userType' | 'redirecting'
  >('paymentMethod');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { userBikeData, setUserBikeData } = useUserBikeStore();
  const { token } = useTokenStore();
  const { prima } = usePrimaStore();
  const insuranceService = useInsuranceService();
  const primaData: PrimaData | null = prima?.success ? prima.data : null;

  useEffect(() => {
    if (
      userBikeData?.bike?.rightSideImage &&
      !suraBikletaPicture &&
      !wasImageManuallyDeleted
    ) {
      setSuraBikletaPicture({
        uri: userBikeData.bike.rightSideImage,
      });
    }
  }, [userBikeData, suraBikletaPicture, wasImageManuallyDeleted]);
  useEffect(() => {
    if (userBikeData?.bike?.serialNumberImage && !suraSerialNumber) {
      setSuraSerialNumber({
        uri: userBikeData.bike.serialNumberImage,
      });
    }
  }, [userBikeData, suraSerialNumber, wasImageManuallyDeleted]);

  useEffect(() => {
    if (userBikeData?.bike?.padlockImage && !suraPadlockPicture) {
      setSuraPadlockPicture({
        uri: userBikeData.bike.padlockImage,
      });
    }
  }, [userBikeData, suraPadlockPicture, wasImageManuallyDeleted]);
  const bikePictureRef = useRef<HTMLInputElement>(null);
  const serialNumberRef = useRef<HTMLInputElement>(null);
  const padlockPictureRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: (value: ImageData) => void
  ) => {
    if (setter === setSuraBikletaPicture) {
      setWasImageManuallyDeleted(false);
    }
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = {
          uri: reader.result as string,
          file: file,
        };
        setter(imageData);

        // Actualizar userBikeData con la imagen correspondiente
        if (userBikeData) {
          const updatedData = {
            ...userBikeData,
            bike: {
              ...userBikeData.bike,
              rightSideImage:
                setter === setSuraBikletaPicture
                  ? imageData.uri
                  : userBikeData.bike.rightSideImage,
              serialNumberImage:
                setter === setSuraSerialNumber
                  ? imageData.uri
                  : userBikeData.bike.serialNumberImage,
              padlockImage:
                setter === setSuraPadlockPicture
                  ? imageData.uri
                  : userBikeData.bike.padlockImage,
            },
          };
          setUserBikeData(updatedData);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = (setter: (value: ImageData) => void) => {
    if (setter === setSuraBikletaPicture) {
      setWasImageManuallyDeleted(true);
    }
    setter(null);

    // Eliminar la imagen correspondiente de userBikeData
    if (userBikeData) {
      const updatedData = {
        ...userBikeData,
        bike: {
          ...userBikeData.bike,
          rightSideImage:
            setter === setSuraBikletaPicture
              ? undefined
              : userBikeData.bike.rightSideImage,
          serialNumberImage:
            setter === setSuraSerialNumber
              ? undefined
              : userBikeData.bike.serialNumberImage,
          padlockImage:
            setter === setSuraPadlockPicture
              ? undefined
              : userBikeData.bike.padlockImage,
        },
      };
      setUserBikeData(updatedData);
    }
  };

  const isButtonDisabled = useMemo(() => {
    return (
      !suraBikletaPicture?.uri ||
      !suraSerialNumber?.uri ||
      !suraPadlockPicture?.uri
    );
  }, [suraBikletaPicture, suraSerialNumber, suraPadlockPicture]);

  const handleBack = () => {
    navigate('/insurance/plan-description');
  };

  const handleCloseActionSheet = () => {
    onClose();
    setStep('userType');
  };

  const handleSameOwner = async () => {
    setIsSameOwner(true);
    setStep('redirecting');
    const insuranceData: InsurancePolicyCreate = {
      userId: userBikeData?.user?._id,
      email: userBikeData?.user?.email,
      bicycleId: userBikeData?.bike?._id,
      brand: userBikeData?.bike?.brand,
      model: userBikeData?.bike?.model,
      year: userBikeData?.bike?.year,
      payer: {
        firstName: userBikeData?.user?.firstname,
        lastName: userBikeData?.user?.lastname,
        rut: userBikeData?.user?.idNumber,
        email: userBikeData?.user?.email,
        prefix: userBikeData?.user?.phoneRegion,
        phoneNumber: userBikeData?.user?.phone,
        direction: '',
      },
      marketValue: primaData?.marketValue,
      depreciatedValue: primaData?.depreciatedValue,
      insurance: 'SURA',
      plan: 'STANDARD',
      amount: selectedQuote
        ? primaData?.monthlyPrimaValue.toString()
        : primaData?.annualPrimaValue.toString(),
      provider: 'mercado_pago',
      method: selectedQuote ? 'subscriptions' : 'checkout_pro',
      successUrl: 'https://www.bikleta.com/linked-app',
    };
    const insuranceRes = await fetchCreateInsurance(insuranceData);
    setStep('paymentMethod');
    const policyId = insuranceRes?._id;
    if (policyId && selectedQuote) {
      // Guardar el intervalo en una variable para poder detenerlo más tarde
      const pollingInterval = setInterval(() => {
        fetchGetPolicyById(policyId, pollingInterval);
      }, 5000);
      
      // Establecer un timeout para detener el polling después de un tiempo máximo
      setTimeout(() => {
        clearInterval(pollingInterval);
        console.log('Polling stopped after timeout');
      }, 600000); // 10 minutos
    }
  };

  const fetchCreateInsurance = async (insuranceData: InsurancePolicyCreate) => {
    if (!token) {
      alert('No token');
      return;
    }
    try {
      const res = await insuranceService.createInsurancePolicy(
        token,
        insuranceData
      );
      if (res.success) {
        onClose();
        const mpUrl = res.data.payment.details.initPoint;

        if (mpUrl) {
          // Enviar un mensaje al WebView para que la app nativa maneje la apertura de la URL
          window.postMessage(
            JSON.stringify({
              type: 'OPEN_EXTERNAL_URL',
              url: mpUrl
            }),
            '*'
          );
        }
        return res.data;
      } else {
        onClose();
        alert('Error creating insurance policy:' + res.message);
      }
    } catch (error) {
      alert('Error in fetchCreateInsurance:' + error);
      onClose();
    }
  };

  const fetchGetPolicyById = async (policyId: string, intervalId?: NodeJS.Timeout) => {
    if (!token) {
      alert('No token');
      if (intervalId) clearInterval(intervalId);
      return;
    }
    try {
      const res = await insuranceService.getPolicyById(policyId, token);
      if (res.success) {
        // Si la respuesta es exitosa, detener el polling
        if (intervalId) clearInterval(intervalId);
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage("RETURN_TO_CONGRATULATIONS");
          return;
        }
      } else {
        console.log('Policy not ready yet:', res.message);
      }
    } catch (error) {
      console.log('Error in fetchGetPolicyById:', error);
    }
  };

  const handleDifferentOwner = () => {
    setIsSameOwner(false);
    onClose();
    navigate('payment-user-data', { state: { selectedQuote } });
  };

  const handleCancel = () => {
    onClose();
  };

  return {
    // Estados
    suraBikletaPicture,
    suraSerialNumber,
    suraPadlockPicture,
    step,
    isOpen,
    prima: primaData,

    // Referencias
    bikePictureRef,
    serialNumberRef,
    padlockPictureRef,

    // Handlers
    setSuraBikletaPicture,
    setSuraSerialNumber,
    setSuraPadlockPicture,
    setStep,
    setSelectedQuote,
    handleFileChange,
    handleDeleteImage,
    handleBack,
    onOpen,
    handleCloseActionSheet,
    handleSameOwner,
    handleDifferentOwner,
    handleCancel,
    onClose,

    // Computed
    isButtonDisabled,
  };
};

export type HirePlanControllerType = ReturnType<typeof useHirePlanController>;
