import React from "react";
import {
  Box,
  Button,
  Text,
  VStack,
  Image,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import "./styles.css";
import { BackIcon, BikeIcon, CrossIcon, PadlockIcon, SerialNumberIcon, CreditCardIcon, CreditCardCheckIcon } from "../components/Icons";
import { CustomActionSheet } from "../components/CustomActionSheet";
import { useHirePlanController, ImageData } from "./HirePlanController";
import { usePriceFormatter } from "../../../utils/usePriceFormatter";
import { mercadoPagoImage } from "../../../resources/images";

interface ImageUploaderProps {
  imageData: ImageData;
  setImageData: (value: ImageData) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  label: string;
  icon: React.ReactNode;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>, setter: (value: ImageData) => void) => void;
  handleDeleteImage: (setter: (value: ImageData) => void) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  imageData,
  setImageData,
  inputRef,
  label,
  icon,
  handleFileChange,
  handleDeleteImage,
}) => (
  <Box>
    <Text className="cardTitle">{label}</Text>
    <Box
      className="imagePressable"
      onClick={() => inputRef.current?.click()}
    >
      {imageData?.uri ? (
        <>
          <Box
            className="deleteButton"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteImage(setImageData);
            }}
          >
            <CrossIcon />
          </Box>
          <Image
            src={imageData.uri}
            alt={label}
            className="imagePreview"
          />
        </>
      ) : (
        <VStack>
          {icon}
          <Text className="imageText">{"Selecciona para cargar"}</Text>
        </VStack>
      )}
    </Box>
    <input
      type="file"
      accept="image/*"
      onChange={(e) => handleFileChange(e, setImageData)}
      ref={inputRef}
      className="imageInput"
    />
  </Box>
);

const HirePlan = () => {
  const {
    suraBikletaPicture,
    suraSerialNumber,
    suraPadlockPicture,
    step,
    isOpen,
    prima,
    bikePictureRef,
    serialNumberRef,
    padlockPictureRef,
    setSuraBikletaPicture,
    setSuraSerialNumber,
    setSuraPadlockPicture,
    setStep,
    setSelectedQuote,
    handleFileChange,
    handleDeleteImage,
    handleBack,
    onOpen,
    handleCloseActionSheet,
    handleSameOwner,
    handleDifferentOwner,
    handleCancel,
    isButtonDisabled
  } = useHirePlanController();
  const { formatPrice } = usePriceFormatter();

  return (
    <Box className="hirePlan">
      <Button
        variant="ghost"
        onClick={handleBack}
        color="#0D0C0C"
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        pl={0}
        leftIcon={<BackIcon />}
        p={0}
        iconSpacing={0}
        ml={-2}
      />
      <Text className="title">¡Último paso!</Text>
      <Text className="text">Adjunta las siguientes fotos para finalizar.</Text>

      <ImageUploader
        imageData={suraBikletaPicture}
        setImageData={setSuraBikletaPicture}
        inputRef={bikePictureRef}
        label="Foto de tu bicicleta"
        icon={<BikeIcon />}
        handleFileChange={handleFileChange}
        handleDeleteImage={handleDeleteImage}
      />

      <ImageUploader
        imageData={suraSerialNumber}
        setImageData={setSuraSerialNumber}
        inputRef={serialNumberRef}
        label="Foto del número de serie"
        icon={<Box ml={4}><SerialNumberIcon /></Box>}
        handleFileChange={handleFileChange}
        handleDeleteImage={handleDeleteImage}
      />

      <ImageUploader
        imageData={suraPadlockPicture}
        setImageData={setSuraPadlockPicture}
        inputRef={padlockPictureRef}
        label="Foto del candado con el que aseguras tu bicicleta"
        icon={<PadlockIcon />}
        handleFileChange={handleFileChange}
        handleDeleteImage={handleDeleteImage}
      />

      <Box className="cardHire cardPrice">
        <Box>
          <Text className="textCardPrice">12 cuotas</Text>
          <Text className="textCardPrice smallText">UF {prima.monthlyUfValue.toFixed(5)} IVA incluido</Text>
        </Box>
        <Text className="textCardPrice bigPrice">${formatPrice(prima.monthlyPrimaValue)}</Text>
      </Box>
      <Box className="cardHire cardPrice secondaryCard">
        <Box>
          <Text className="textCardPrice secondaryPrice">1 cuota</Text>
          <Text className="textCardPrice smallText secondaryPrice">
            UF {prima.annualUfValue.toFixed(5)} IVA incluido
          </Text>
        </Box>
        <Text className="textCardPrice bigPrice secondaryPrice">${formatPrice(prima.annualPrimaValue)}</Text>
      </Box>
      <Text className="secondaryText smallSmall">
        Ajustado al valor UF del día (${formatPrice(prima.ufValue)})
      </Text>

      <HStack justifyContent={'center'} mt={4}>
        <Button
          className="uploadButton"
          isDisabled={isButtonDisabled}
          onClick={onOpen}
        >
          Ir a pagar
        </Button>
      </HStack>
      <CustomActionSheet
        icon={step === 'userType' ? <CreditCardIcon /> : (step === 'paymentMethod' ? <CreditCardCheckIcon /> : null)}
        title={step === 'userType' ? "Ir a pagar" : (step === 'paymentMethod' ? "Elige cómo pagar" : "")}
        isOpen={isOpen}
        onClose={() => {
          handleCloseActionSheet();
          setStep('paymentMethod');
        }}
        buttons={step === 'userType' ? [
          {
            text: "Sí, es la misma persona",
            onPress: handleSameOwner,
            color: "#E3E829"
          },
          {
            text: "No, es una persona diferente",
            onPress: handleDifferentOwner,
            withBorder: true
          },
          {
            text: "Cancelar",
            onPress: () => {
              handleCancel();
              setStep('paymentMethod');
            },
            color: "white"
          }
        ] : (step === 'paymentMethod' ? [
          {
            text: "Cancelar",
            onPress: handleCancel,
            color: "white",
            withBorder: true
          }
        ] : [])}
      >
        {step === 'userType' ? (
          <VStack spacing={2} alignItems="center">
            <Text textAlign="center" fontSize="16px" color="#777672">
              Vas a pagar con <strong>Mercado Pago</strong>.
            </Text>
            <Text textAlign="center" fontSize="16px" color="#777672">
              ¿El pagador es el mismo que el propietario de la bicicleta?
            </Text>
          </VStack>
        ) : step === 'paymentMethod' ? (
          <VStack spacing={0} alignItems="center" mb={4} mt={-3}>
            <Button
              onClick={() => {
                setStep('userType');
                setSelectedQuote(false);
              }}
              className="buttonPayment"
            >
              <VStack alignItems="left" textAlign="left" spacing={0}>
                <Text fontSize="14px" fontWeight={400} color="#0D0C0C">
                  1 cuota
                </Text>
                <Text fontSize="12px" fontWeight={400} color="#5B5B57">
                  Paga anualmente de una sola vez
                </Text>
              </VStack>
              <Image src={mercadoPagoImage} alt="Mercado Pago" w="107px" h="29px" />
            </Button>
            <Button
              onClick={() => {
                setStep('userType');
                setSelectedQuote(true);
              }}
              className="buttonPayment"
            >
              <VStack alignItems="left" textAlign="left" spacing={0}>
                <Text fontSize="14px" fontWeight={400} color="#0D0C0C">
                  12 cuotas
                </Text>
                <Text fontSize="12px" fontWeight={400} color="#5B5B57">
                  Suscríbete en cuotas mensuales sin interés
                </Text>
              </VStack>
              <Image src={mercadoPagoImage} alt="Mercado Pago" w="107px" h="29px" />
            </Button>
          </VStack>
        ) : (
          <VStack spacing={2} alignItems="center">
            <Box position="relative" width="80px" height="80px" mb={4} display="flex" alignItems="center" justifyContent="center">
              <Spinner
                thickness="5px"
                speed="0.7s"
                color="#E3E829"
                height="42px"
                width="42px"
              />
            </Box>
            <Text textAlign="center" fontSize="20px" fontWeight="700" color="#0D0C0C">
              Redirigiendo a Mercado Pago
            </Text>
            <Text textAlign="center" fontSize="16px" color="#777672">
              Serás redirigido a la página de pago y luego volverás a la app
            </Text>
          </VStack>
        )}
      </CustomActionSheet>
    </Box>
  );
};

export default HirePlan;
