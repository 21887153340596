import React, { useEffect, useState } from "react";
import { Box, Button, Flex, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import "./styles.css";
import { BackIcon } from "../components/Icons";
import { usePlanDescriptionController } from "./PlanDescriptionController";
import { usePriceFormatter } from "../../../utils/usePriceFormatter";

const PlanDescription = () => {
  const [isLoading, setIsLoading] = useState(true);
  const controller = usePlanDescriptionController();
  const { formatPrice } = usePriceFormatter();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <Flex
        height="100vh"
        width="100%"
        justifyContent="center"
        alignItems="center"
        backgroundColor="white"
        flexDirection="column"
      >
        <Spinner
          thickness="5px"
          speed="0.7s"
          color="#E3E829"
          height="42px"
          width="42px"
          mb={6}
        />
        <VStack spacing={1}>
          <Text
            fontSize="xl"
            fontWeight="600"
            color="#0D0C0C"
            style={{ whiteSpace: "pre-line" }}
          >
            {`Cotizando el seguro\nideal para tu bikleta`}
          </Text>
        </VStack>
      </Flex>
    );
  }

  return (
    <Box className="planDescription">
      <Box className="planContent">
        <Button
          width="fit-content"
          variant="ghost"
          onClick={controller.handleBack}
          mb={4}
          color="#0D0C0C"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          leftIcon={<BackIcon />}
          p={0}
          ml={-1}
        />
        <Text className="boldPD">{`TE OFRECEMOS \nEL PLAN IDEAL \nPARA TU BIKLETA`}</Text>
        <Box className="cardPD">
          <Box className="cardBorderPD">
            <Text className="planPD">Plan Bikleta · Sura</Text>
            <Text className="planPD">Antirrobos</Text>
            <Text className="planSubTitlePD">Cobertura destinada a proteger tu bici en caso de robo, según el valor de tu Bikleta</Text>
            <Box className="cardPD cardPricePD">
              <Box>
                <Text className="textCardPricePD">12 cuotas</Text>
                <Text className="textCardPricePD smallTextPD">
                  UF {controller.prima.monthlyUfValue.toFixed(5)} IVA incluido
                </Text>
              </Box>
              <Text className="textCardPricePD bigPricePD">${formatPrice(controller.prima.monthlyPrimaValue)}</Text>
            </Box>
            <Box className="cardPD cardPricePD secondaryCardPD">
              <Box>
                <Text className="textCardPricePD secondaryPricePD">1 cuota</Text>
                <Text className="textCardPricePD smallTextPD secondaryPricePD">
                  UF {controller.prima.annualUfValue.toFixed(5)} IVA incluido
                </Text>
              </Box>
              <Text className="textCardPricePD bigPricePD secondaryPricePD">
                ${formatPrice(controller.prima.annualPrimaValue)}
              </Text>
            </Box>
            <Text className="secondaryTextPD smallSmallPD">
              Ajustado al valor UF del día (${formatPrice(controller.prima.ufValue)})
            </Text>
          </Box>
        </Box>
        <Text className="secondaryTextPD smallPD">
          Deducible por robo o pérdida total será 10% el valor del ciclo. Para el
          cálculo del deducible se considerará el valor comercial en el minuto del
          siniestro tomando en cuenta la depreciación del ciclo. Mínimo UF 3
        </Text>
        <HStack justifyContent={'center'} mt={4}>
          <Button onClick={controller.onPressHire} className="hireButtonPD" width="100%">
            Contratar
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

export default PlanDescription;