import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  VStack,
  Text,
  Box,
  BoxProps,
  ModalHeader
} from '@chakra-ui/react';

interface ActionSheetProps extends BoxProps {
  icon?: React.ReactNode;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  iconBackgroundColor?: string;
  buttons: {
    text: string;
    onPress: () => void;
    color?: string;
    icon?: React.ReactNode;
    withBorder?: boolean;
  }[];
  children?: React.ReactNode;
}

export const CustomActionSheet = ({
  icon,
  title,
  children,
  isOpen,
  onClose,
  buttons,
  iconBackgroundColor = '#EBECA2'
}: ActionSheetProps) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay
        bg="rgba(13, 12, 12, 0.5)"
      />
      <ModalContent
        position="fixed"
        bottom={0}
        mb={0}
        mx={0}
        borderTopRadius="24px"
        borderBottomRadius={0}
        width="100%"
        maxWidth="100%"
        bg="white"
      >
        <ModalHeader display="flex" justifyContent="center" pb={3}>
          <Box w="32px" h="4px" bg="#777672" borderRadius="8px" />
        </ModalHeader>
        <ModalBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          pb={8}
          px={4}
          maxHeight="90vh"
          overflowY="auto"
        >
          {icon && (
            <Box
              width="64px"
              height="64px"
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg={iconBackgroundColor}
              mb={4}
            >
              {icon}
            </Box>
          )}

          <Text
            fontSize="20px"
            fontWeight="500"
            color="#0D0C0C"
            mb={2}
            textAlign="center"
          >
            {title}
          </Text>

          {children && (
            <Box my={4} width="100%">
              {children}
            </Box>
          )}

          <VStack spacing={3} width="100%" mt="auto" mb={-2}>
            {buttons.map((button, index) => (
              <Button
                key={index}
                onClick={button.onPress}
                width="75%"
                height="56px"
                bg={button.color || "transparent"}
                border={button.withBorder ? "1px solid #0D0C0C" : "none"}
                borderRadius="100px"
                _hover={{ opacity: 0.8 }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                className="ActionSheetButton"
              >
                <Text
                  fontSize="16px"
                  fontWeight="400"
                  color={button.withBorder ? "#0D0C0C" : "inherit"}
                >
                  {button.text}
                </Text>
                {button.icon}
              </Button>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
