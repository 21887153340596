import { useNavigate } from "react-router-dom";
import { usePrimaStore } from "../../../services/Insurance/InsuranceStore";
import { PrimaData } from "../../../services/Insurance/InsuranceInterface";

export const usePlanDescriptionController = () => {
  const navigate = useNavigate();

  const { prima } = usePrimaStore();
  const primaData: PrimaData | null = prima?.success ? prima.data : null;
  const onPressHire = () => {
    navigate("/insurance/hire-plan");
  };

  const handleBack = () => {
    navigate("/insurance");
  };

  return {
    // Properties
    prima: primaData,
    // Methods
    onPressHire,
    handleBack
  }
}