import create from 'zustand';
import type { PrimaData, PrimaResponse, UserBikeData } from './InsuranceInterface';

interface UserBikeStore {
  userBikeData: UserBikeData | null;
  setUserBikeData: (data: UserBikeData) => void;
}

interface TokenStore {
  token: string | null;
  setToken: (data: string | null) => void;
}

interface PrimaStore {
  prima: PrimaResponse | null;
  setPrima: (data: PrimaResponse) => void;
}

const useUserBikeStore = create<UserBikeStore>((set) => ({
  userBikeData: null,
  setUserBikeData: (data) => set({ userBikeData: data }),
}));

const useTokenStore = create<TokenStore>((set) => ({
  token: null,
  setToken: (data) => set({ token: data }),
}));

const usePrimaStore = create<PrimaStore>((set) => ({
  prima: null,
  setPrima: (data) => set({ prima: data }),
}));

export { useUserBikeStore, useTokenStore, usePrimaStore };
