import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { BackIcon } from '../components/Icons';
import { usePaymentUserController } from './PaymentUserController';
import './styles.css';
import { usePlanDescriptionController } from '../PlanDescription/PlanDescriptionController';
import { usePriceFormatter } from '../../../utils/usePriceFormatter';

const PaymentUserData = () => {
  const addressInputRef = useRef<HTMLInputElement>(null);
  const [isLastFieldFocused, setIsLastFieldFocused] = useState(false);
  const {
    formData,
    handleInputChange,
    handleBlur,
    handleBack,
    handleSubmit,
    isFormValid,
    errors
  } = usePaymentUserController();
  const { prima } = usePlanDescriptionController();
  const { formatPrice } = usePriceFormatter();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="paymentUserData" style={{ paddingBottom: isLastFieldFocused ? '200px' : '16px' }}>
      <Button
        variant="ghost"
        onClick={handleBack}
        color="#0D0C0C"
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        pl={0}
        leftIcon={<BackIcon />}
        p={0}
        iconSpacing={0}
        ml={-2}
      />
      <Text className="titlePU">Datos del pagador</Text>

      <VStack spacing={6} align="stretch" mt={6}>
        <FormControl variant={'floating'} isInvalid={!!errors.firstName}>
          <Input
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="InputPayment"
            placeholder=""
          />
          <FormLabel className="FormLabelPayment">Nombre/s</FormLabel>
          <FormErrorMessage>{errors.firstName}</FormErrorMessage>
        </FormControl>

        <FormControl variant={'floating'} isInvalid={!!errors.lastName}>
          <Input
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="InputPayment"
            placeholder=""
          />
          <FormLabel className="FormLabelPayment">Apellido/s</FormLabel>
          <FormErrorMessage>{errors.lastName}</FormErrorMessage>
        </FormControl>

        <FormControl variant={'floating'} isInvalid={!!errors.rut}>
          <Input
            name="rut"
            value={formData.rut}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="InputPayment"
            placeholder=""
          />
          <FormLabel className="FormLabelPayment">RUT</FormLabel>
          <FormErrorMessage>{errors.rut}</FormErrorMessage>
        </FormControl>

        <FormControl variant={'floating'} isInvalid={!!errors.email}>
          <Input
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="InputPayment"
            placeholder=""
          />
          <FormLabel className="FormLabelPayment">Email</FormLabel>
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>

        <HStack spacing={4}>
          <FormControl maxW="100px" variant={'floating'} isInvalid={!!errors.prefix}>
            <Input
              name="prefix"
              type='tel'
              value={formData.prefix}
              onChange={handleInputChange}
              onBlur={handleBlur}
              className="InputPayment"
            />
            <FormLabel className="FormLabelPayment">Prefijo</FormLabel>
            <FormErrorMessage>{errors.prefix}</FormErrorMessage>
          </FormControl>

          <FormControl flex={1} variant={'floating'} isInvalid={!!errors.phone}>
            <Input
              name="phone"
              type='tel'
              value={formData.phone}
              onChange={handleInputChange}
              onBlur={handleBlur}
              className="InputPayment"
              placeholder=""
            />
            <FormLabel className="FormLabelPayment">Teléfono</FormLabel>
            <FormErrorMessage>{errors.phone}</FormErrorMessage>
          </FormControl>
        </HStack>

        <FormControl variant={'floating'} isInvalid={!!errors.address}>
          <Input
            ref={addressInputRef}
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            className="InputPayment"
            placeholder=""
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
            enterKeyHint="done"
            onFocus={() => {
              setIsLastFieldFocused(true);
            }}
            onBlur={(e) => {
              handleBlur(e);
              setIsLastFieldFocused(false);
            }}
          />
          <FormLabel className="FormLabelPayment">Dirección</FormLabel>
          <FormErrorMessage>{errors.address}</FormErrorMessage>
        </FormControl>
      </VStack>

      <Box className="cardHire cardPrice">
        <Box>
          <Text className="textCardPrice">12 cuotas</Text>
          <Text className="textCardPrice smallText">UF {prima.monthlyUfValue.toFixed(5)} IVA incluido</Text>
        </Box>
        <Text className="textCardPrice bigPrice">${formatPrice(prima.monthlyPrimaValue)}</Text>
      </Box>
      <Box className="cardHire cardPrice secondaryCard">
        <Box>
          <Text className="textCardPrice secondaryPrice">1 cuota</Text>
          <Text className="textCardPrice smallText secondaryPrice">
            UF {prima.annualUfValue.toFixed(5)} IVA incluido
          </Text>
        </Box>
        <Text className="textCardPrice bigPrice secondaryPrice">${formatPrice(prima.annualPrimaValue)}</Text>
      </Box>
      <Text className="secondaryText smallSmall" mb={4}>
        Ajustado al valor UF del día (${formatPrice(prima.ufValue)})
      </Text>
      <HStack justifyContent={'center'}>
        <Button
          className="uploadButton"
          isDisabled={!isFormValid()}
          onClick={handleSubmit}
        >
          Ir a pagar
        </Button>
      </HStack>
    </Box>
  );
};

export default PaymentUserData;