import { useMemo } from 'react';

export const usePriceFormatter = () => {
  const formatPrice = (price?: number | string) => {
    if (!price) return '0';
    const number = typeof price === 'string' ? parseFloat(price) : price;
    const isInteger = Number.isInteger(number);
    return number.toLocaleString('es-CL', {
      minimumFractionDigits: isInteger ? 0 : 2,
      maximumFractionDigits: isInteger ? 0 : 2
    });
  };

  return useMemo(() => ({ formatPrice }), []);
};
