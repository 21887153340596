import { useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  usePrimaStore,
  useTokenStore,
  useUserBikeStore,
} from '../../../services/Insurance/InsuranceStore';
import type {
  InsurancePolicyCreate,
  Payer,
  PrimaData,
} from '../../../services/Insurance/InsuranceInterface';
import * as Yup from 'yup';
import type { ObjectSchema } from 'yup';
import { useInsuranceService } from '../../../services/Insurance/InsuranceService';

export interface PaymentUserDataForm {
  firstName: string;
  lastName: string;
  rut: string;
  email: string;
  phone: string;
  prefix: string;
  address: string;
}

export const usePaymentUserController = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedQuote = location.state?.selectedQuote;

  const { userBikeData, setUserBikeData } = useUserBikeStore();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const insuranceService = useInsuranceService();
  const { token } = useTokenStore();
  const { prima } = usePrimaStore();
  const primaData: PrimaData | null = prima?.success ? prima.data : null;

  const validationSchema = useMemo<ObjectSchema<any>>(
    () =>
      Yup.object({
        firstName: Yup.string()
          .required('El nombre es requerido')
          .matches(
            /^[a-zA-Zá-úÁ-Ú ]*$/,
            'El nombre solo puede contener letras y espacios'
          ),
        lastName: Yup.string()
          .required('El apellido es requerido')
          .matches(
            /^[a-zA-Zá-úÁ-Ú ]*$/,
            'El apellido solo puede contener letras y espacios'
          ),
        rut: Yup.string()
          .required('El RUT es requerido')
          .matches(
            /^(?!12345678|123456789|01234567|012345678)\d{7,8}(-?[0-9Kk])?$/,
            'RUT inválido'
          ),
        email: Yup.string()
          .required('El email es requerido')
          .email('Email inválido'),
        prefix: Yup.string()
          .required('El prefijo es requerido')
          .matches(
            /^[+][0-9]+$/,
            'El prefijo debe estar compuesto de un + seguido de 1 a 3 dígitos'
          )
          .min(
            2,
            'El prefijo debe estar compuesto de un + seguido de 1 a 3 dígitos'
          )
          .max(
            4,
            'El prefijo debe estar compuesto de un + seguido de 1 a 3 dígitos'
          ),
        phone: Yup.string()
          .required('El teléfono es requerido')
          .matches(/^[0-9]*$/, 'El teléfono solo puede contener números')
          .min(7, 'El teléfono debe tener al menos 7 números')
          .max(12, 'El teléfono no puede tener más de 12 números'),
        address: Yup.string()
          .required('La dirección es requerida')
          .min(5, 'La dirección debe tener al menos 5 caracteres')
          .max(50, 'La dirección no puede tener más de 100 caracteres'),
      }),
    []
  );

  // Siempre inicializar con campos vacíos ya que es otro pagador
  const [formData, setFormData] = useState<PaymentUserDataForm>({
    firstName: '',
    lastName: '',
    rut: '',
    email: '',
    phone: '',
    prefix: '+56',
    address: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));

    if (!userBikeData) return;

    const updatedPayer = {
      ...userBikeData.payer,
      firstname: name === 'firstName' ? value.trim() : formData.firstName,
      lastname: name === 'lastName' ? value.trim() : formData.lastName,
      idNumber: name === 'rut' ? value.trim() : formData.rut,
      email: name === 'email' ? value : formData.email,
      phone: name === 'phone' ? value : formData.phone,
      phoneRegion: name === 'prefix' ? value : formData.prefix,
      address: name === 'address' ? value.trim() : formData.address,
    };

    setUserBikeData({
      ...userBikeData,
      payer: updatedPayer,
    });
  };

  const handleBack = () => {
    navigate('/insurance/hire-plan');
  };

  const handleSubmit = async () => {
    if (!userBikeData) return;

    const finalPayer: Payer = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      rut: formData.rut,
      email: formData.email,
      prefix: formData.prefix,
      phoneNumber: formData.phone,
      direction: formData.address,
    };

    setUserBikeData({
      ...userBikeData,
      payer: finalPayer,
    });

    const insuranceData: InsurancePolicyCreate = {
      userId: userBikeData?.user?._id,
      email: userBikeData?.user?.email,
      bicycleId: userBikeData?.bike?._id,
      brand: userBikeData?.bike?.brand,
      model: userBikeData?.bike?.model,
      year: userBikeData?.bike?.year,
      payer: finalPayer,
      marketValue: primaData?.marketValue,
      depreciatedValue: primaData?.depreciatedValue,
      insurance: 'SURA',
      plan: 'STANDARD',
      amount: selectedQuote ? primaData?.monthlyPrimaValue.toString() : primaData?.annualPrimaValue.toString(),
      provider: 'mercado_pago',
      method: selectedQuote ? 'subscriptions' : 'checkout_pro',
      successUrl: 'https://www.bikleta.com/linked-app'
    };
    await fetchCreateInsurance(insuranceData);
    insuranceService.createInsurancePolicy(token, insuranceData);
  };

  const fetchCreateInsurance = async (insuranceData: InsurancePolicyCreate) => {
    try {
      const res = await insuranceService.createInsurancePolicy(
        token,
        insuranceData
      );
      if (res.success) {
        const mpUrl = res.data.payment.details.initPoint;

        if (mpUrl) {
          // Intentar abrir en una nueva ventana
          const newWindow = window.open(mpUrl, '_blank');

          // Si la ventana fue bloqueada, redirigir en la misma ventana
          if (newWindow === null) {
            alert(
              'Por favor, aseguérate de permitir ventanas emergentes y haz clic en el botón para reintentar el pago'
            );
            // Crear un botón temporal para el usuario
            const payButton = document.createElement('a');
            payButton.href = mpUrl;
            payButton.target = '_blank';
            payButton.click();
          }
        } else {
          alert('No Mercado Pago URL provided in response');
        }
      } else {
        alert('Error creating insurance policy:' + res.message);
      }
    } catch (error) {
      alert('Error in fetchCreateInsurance:' + error);
    }
  };
  const isFormValid = () => {
    try {
      validationSchema.validateSync(formData, { abortEarly: false });
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Validar el campo cuando pierde el foco
    try {
      // Crear un schema temporal solo para el campo actual
      const fieldValidation = Yup.object({
        [name]: validationSchema.fields[name],
      });

      fieldValidation.validateSync({ [name]: value }, { abortEarly: false });
      setErrors(prev => ({ ...prev, [name]: '' }));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors(prev => ({ ...prev, [name]: error.errors[0] }));
      }
    }
  };

  return {
    formData,
    handleInputChange,
    handleBlur,
    handleBack,
    handleSubmit,
    isFormValid,
    errors,
  };
};
