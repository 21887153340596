import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsuranceService } from '../../services/Insurance/InsuranceService';
import {
  usePrimaStore,
  useTokenStore,
  useUserBikeStore,
} from '../../services/Insurance/InsuranceStore';
import { UserBikeData } from '../../services/Insurance/InsuranceInterface';
import * as yup from 'yup';

const serialNumberSchema = yup
  .string()
  .required('El número de serie es requerido')
  .max(20, 'El número de serie no puede tener más de 20 caracteres');

const useInsuranceController = () => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const insuranceService = useInsuranceService();
  const { userBikeData, setUserBikeData } = useUserBikeStore();
  const { prima, setPrima } = usePrimaStore();
  const { token, setToken } = useTokenStore();

  useEffect(() => {
    // Configurar listeners
    window.addEventListener('message', handleMessage);
    document.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      document.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    fetchPrima();
  }, [userBikeData?.bike?.year]);

  const handleMessage = async event => {
    try {
      const data =
        typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

      // Solo procesamos mensajes con nuestro identificador específico
      if (data.type === 'BIKLETA_DATA' && !userBikeData) {
        setUserBikeData(data.payload);
        setToken(data?.token);
        if (!data?.payload?.bike?.year) return;
        const res = await insuranceService.getPrimaValue(data?.token, {
          brand: data?.payload?.bike?.brand,
          model: data?.payload?.bike?.model,
          year: data?.payload?.bike?.year,
          type: data?.payload?.bike?.bikeType,
        });
        if (!res) return;
        setPrima(res);
      }
    } catch (error) {
      // Ignoramos errores de parseo de otros mensajes
      if (
        event.data &&
        typeof event.data === 'string' &&
        event.data.includes('BIKLETA_DATA')
      ) {
        alert('Error procesando mensaje de Bikleta: ' + error);
      }
    }
  };

  const fetchPrima = async () => {
    if (!userBikeData) return;
    const res = await insuranceService.getPrimaValue(token, {
      brand: userBikeData?.bike?.brand,
      model: userBikeData?.bike?.model,
      year: Number(userBikeData?.bike?.year),
      type: userBikeData?.bike?.bikeType,
    });
    if (!res) return;
    setPrima(res);
  };

  const handleBack = () => {
    // window.ReactNativeWebView.postMessage('RETURN_TO_SURA_APP');
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("RETURN_TO_SURA_APP");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (userBikeData) {
      const updatedData: UserBikeData = {
        ...userBikeData,
        bike: {
          ...userBikeData.bike,
          serialNumber: value,
        },
      };
      setUserBikeData(updatedData);
      // Necesito poder remodificar el Año
      fetchPrima();
    }
    if (name === 'serialNumber') {
      try {
        serialNumberSchema.validateSync(value);
        setErrors(prev => ({ ...prev, serialNumber: '' }));
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          // Solo mostramos error si no está vacío
          if (value !== '') {
            setErrors(prev => ({ ...prev, serialNumber: error.message }));
          } else {
            setErrors(prev => ({ ...prev, serialNumber: '' }));
          }
        }
      }
    }
  };

  const handleSubmit = () => {
    navigate('plan-description');
  };

  const openModal = (field: string) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOptionSelect = (year: string) => {
    if (userBikeData) {
      const updatedData: UserBikeData = {
        ...userBikeData,
        bike: {
          ...userBikeData.bike,
          year,
        },
      };
      setUserBikeData(updatedData);
    }
    closeModal();
  };

  const isFormComplete = () => {
    if (!userBikeData?.bike) return false;
    const { brand, model, year, serialNumber } = userBikeData.bike;
    return Boolean(brand && model && year && serialNumber);
  };

  return {
    errors,
    data: userBikeData,
    isModalOpen,
    prima,
    isFormComplete,
    handleBack,
    handleChange,
    handleSubmit,
    openModal,
    closeModal,
    handleOptionSelect,
  };
};

export default useInsuranceController;
