import axios from 'axios';
import type { InsurancePolicyCreate, InsurancePolicyResponse, PolicyByIdResponse, PrimaResponse } from './InsuranceInterface';
import { apiUrl } from '../../conf';

interface InsuranceService {
  getPrimaValue(
    token: string,
    params: {
      brand: string;
      model: string;
      year: number;
      type: string;
    }
  ): Promise<PrimaResponse>;
  createInsurancePolicy(
    token: string,
    data: InsurancePolicyCreate
  ): Promise<InsurancePolicyResponse>;
  getPolicyById: (policyId: string, token: string) => Promise<PolicyByIdResponse>;
}

export const useInsuranceService = (): InsuranceService => {
  const getPrimaValue = async (
    token: string,
    params: {
      brand: string;
      model: string;
      year: number;
      type: string;
    }
  ) => {
    const { brand, model, year, type } = params;

    try {
      const res = await axios.get<PrimaResponse>(apiUrl + '/insurance/prima-value', {
        params: {
          brand,
          model,
          year,
          type,
        },
        headers: {
          Authorization: token,
        },
      });
      return res.data;
    } catch (error) {
      alert('Error getting prima value: ' + error);
      return null;
    }
  };

  const createInsurancePolicy = async (
    token: string,
    data: InsurancePolicyCreate
  ) => {
    // alert(JSON.stringify(data));
    try {
      const res = await axios.post<InsurancePolicyResponse>(apiUrl + '/insurance', data, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      });
      if (!res.data.success) return null;
      // alert(JSON.stringify(res.data.data.payment.details.initPoint));
      return res.data;
    } catch (error) {
      alert('Error creating insurance policy: ' + error);
      return null;
    }
  };

  const getPolicyById = async (policyId: string, token: string): Promise<PolicyByIdResponse> => {
    try {
      const res = await axios.get<PolicyByIdResponse>(`${apiUrl}/insurance/${policyId}`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      });
      return res.data;
    } catch (error) {
      console.log('Error fetching policy:', error);
      return { success: false, message: `Error fetching policy: ${error}` } as PolicyByIdResponse;
    }
  };

  return {
    getPrimaValue,
    createInsurancePolicy,
    getPolicyById
  };
};
