import React, { useState } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Select,
  InputGroup,
  InputRightElement,
  HStack,
  InputLeftElement,
} from "@chakra-ui/react";
import useInsuranceController from "./InsuranceController";
import './styles.css';
import { BackIcon } from "./components/Icons";
import { usePriceFormatter } from "../../utils/usePriceFormatter";

const InsuranceView = () => {
  const {
    errors,
    data,
    isModalOpen,
    prima,
    isFormComplete,
    handleBack,
    handleChange,
    handleSubmit,
    openModal,
    closeModal,
    handleOptionSelect,
  } = useInsuranceController();

  const { formatPrice } = usePriceFormatter();
  const years: string[] = [];
  const currentYear = new Date().getFullYear();
  for (let i = 2022; i <= currentYear; i++) {
    years.push(i.toString());
  }

  return (
    <Box
      w="100%"
      maxW={{ base: "100%", md: "500px" }}
      mx="auto"
      height={'100vh'}
      p={4}
      pt={2}
      bg="#FDFDFC"
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box w="100%" display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Button
          variant="ghost"
          onClick={handleBack}
          color="#0D0C0C"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          leftIcon={<BackIcon />}
          iconSpacing={0}
          p={0}
          ml={-2}
        />
        <Text pl={2} className="headerInsurance">Cotizar</Text>
      </Box>
      <Text className="titleInsurance" mb={2}>Bikleta principal</Text>
      <Text fontSize={"md"} color="gray.600" mb={8}>
        Completa los datos que falten de la bikleta que quieres asegurar.
      </Text>
      {/* stringifeo la data */}
      {/* {data && <pre>{JSON.stringify(data, null, 2)}</pre>} */}

      {/* Marca */}
      <FormControl isInvalid={!!errors.brand} mb={4} variant={'floating'}>
        <Input
          name="brand"
          readOnly
          value={data?.bike?.brand || ''}
          className="InputInsurance"
        />
        <FormLabel className="FormLabelInsurance">Marca</FormLabel>
        <FormErrorMessage>{errors.brand}</FormErrorMessage>
      </FormControl>

      {/* Modelo */}
      <FormControl isInvalid={!!errors.model} mb={4} variant={'floating'}>
        <Input
          name="model"
          readOnly
          value={data?.bike?.model || ''}
          className="InputInsurance"
        />
        <FormLabel className="FormLabelInsurance">Modelo</FormLabel>
        <FormErrorMessage>{errors.model}</FormErrorMessage>
      </FormControl>

      {/* Año */}
      {/* TODO: Cada vez que cambia el año se le pega al get de nuevo porque cambia la depreciacion */}
      <FormControl isInvalid={!!errors.year} mb={4} variant={'floating'}>
        {data?.bike?.year
          ? <Input
              name="year"
              readOnly
              value={data?.bike?.year || ''}
              className="InputInsurance"
              placeholder=""
            />
          : <Select
              name="year"
              value={data?.bike?.year || ''}
              placeholder=""
              className="SelectInsurance"
              onClick={() => {
                if (!data?.bike?.year) {
                  openModal("year")
                }
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <option
                value={data?.bike?.year || ''}
              >
                {data?.bike?.year || ''}
              </option>
          </Select>
        }
        <FormLabel className="FormLabelInsurance">Año</FormLabel>
        <FormErrorMessage>{errors.year}</FormErrorMessage>
      </FormControl>

      {/* Número de Serie */}
      <FormControl isInvalid={!!errors.serialNumber} mb={4} variant={'floating'}>
        <Input
          name="serialNumber"
          value={data?.bike?.serialNumber || ''}
          onChange={handleChange}
          className="InputInsurance"
          placeholder=""
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
          enterKeyHint="done"
        />
        <FormLabel className="FormLabelInsurance">Número de serie</FormLabel>
        <FormErrorMessage>{errors.serialNumber}</FormErrorMessage>
      </FormControl>

      {/* Valor Comercial */}
      <FormControl mb={4} variant={'floating'}>
        <InputGroup size="md">
          <Input
            name="commercialValue"
            readOnly
            value={prima?.success && prima?.data?.marketValue ? formatPrice(prima?.data?.marketValue) : ''}
            className="InputInsurance"
            pr="4.5rem"
          />
          <FormLabel className="FormLabelInsurance">Valor comercial</FormLabel>
          {prima?.success === false ? (
            <InputLeftElement w="100%" display="flex" justifyContent="left">
              <Text fontSize="md" ml={4} mt={2.5} noOfLines={1} color="red.500">No se pudo cotizar tu bici en este momento</Text>
            </InputLeftElement>
          ) : !prima?.data?.marketValue && data?.bike?.year && (
            <InputLeftElement>
              <Text fontSize="md" ml={20} mt={2.5}>Cotizando...</Text>
            </InputLeftElement>)}
          <InputRightElement>
            <Text fontSize="md" mt={2.5}>$</Text>
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <Text color={'#0D0C0C'}>
        El monto máximo a cotizar es de 150 UF, con un monto límite de indemnización de 90 UF.
      </Text>

      {/* Monto a Asegurar */}
      <HStack justifyContent={'space-between'} mt={4} mb={4}>
        <Text className="TotalInsurance">
          Monto a asegurar:
        </Text>
        <Text className="TotalInsurance">
          ${formatPrice(prima?.success ? prima?.data?.depreciatedValue : '0,00')}
        </Text>
      </HStack>

      {/* Botón de Cotizar */}
      <HStack justifyContent={'center'} mt={4}>
        <Button
          mt={4}
          mb={4}
          className="quoteButton"
          bg={isFormComplete() && prima?.success && prima?.data?.depreciatedValue ? "#E3E829" : "#1d1b2034"}
          isDisabled={!isFormComplete() || !prima?.success || !prima?.data?.depreciatedValue}
          onClick={handleSubmit}
          _focus={{
            bg: isFormComplete() && prima?.success && prima?.data?.depreciatedValue ? "#E3E829" : "#1d1b2034",
          }}
        >
          Cotizar
        </Button>
      </HStack>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent
          alignSelf={'center'}
          m={8}
        >
          <ModalHeader>Selecciona el año</ModalHeader>
          <ModalCloseButton
            _focus={{
              boxShadow: 'none',
              outline: 'none',
              border: 'none'
            }}
          />
          <ModalBody
            justifyContent="center"
            alignItems="center">
            {years.map((year) => (
              <VStack
                key={year}
                onClick={() => handleOptionSelect(year)}
                style={{ userSelect: 'none' }}>
                <Box
                  width="100%"
                  py={3}
                  px={4}
                  cursor="pointer"
                  borderBottom="1px solid #F5F3EF"
                  onClick={() => handleOptionSelect(year)}
                  textAlign="center"
                  _hover={{ bg: 'transparent' }}
                  sx={{
                    WebkitTapHighlightColor: 'transparent',
                    '&:active': {
                      backgroundColor: '#F5F3EF !important',
                      borderRadius: '8px'
                    }
                  }}
                >
                  {year}
                </Box>
              </VStack>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InsuranceView;
